
import { defineComponent,onMounted, reactive } from "vue";
import * as moment from "moment";
import DetailContrat from "@/views/still/contrats/DetailContrat.vue";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-widget-4",
  components: {DetailContrat},
  props: {
    widgetClasses: String,
  },
  setup() {

    moment.default.locale("fr");

    const state = reactive({
      drawer: false,
      loaderEnabled: true,
      initialMyList: [],
      myList: [],
      contratSelect: {},
    });


    onMounted(async () => {
      let myList = await getAxios("/getContratSupport/-1");
      state.myList = myList.results;      
      //console.log(state.myList);
      state.loaderEnabled = false;
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_before_today = (d) => {
      const currentDate = moment.default();
      return moment.default(d) > currentDate;
    }

    const affDetail = async (data) => {
      // console.log('Detail',data);
      state.contratSelect = data;
      state.drawer = true;
    };

    const changeContrat = async (ev) => {
      let myList = await getAxios("/getContratSupport/-1");
      state.myList = myList.results;   

    }

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    return {
      state,
      imgConst,
      formatDate,
      test_before_today,
      affDetail,
      changeContrat
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
